

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"dateTimeFormats":{"de-DE":{"short":{"year":"numeric","month":"2-digit","day":"2-digit"},"weekDayDate":{"weekday":"short","day":"numeric","month":"2-digit","year":"2-digit"}},"fr-FR":{"short":{"year":"numeric","month":"2-digit","day":"2-digit"},"weekDayDate":{"weekday":"short","day":"numeric","month":"2-digit","year":"2-digit"}}},"numberFormats":{"de-DE":{"percentLong":{"style":"percent","maximumFractionDigits":2,"minimumFractionDigits":2},"short":{"maximumFractionDigits":0,"minimumFractionDigits":0}},"fr-FR":{"percentLong":{"style":"percent","maximumFractionDigits":2,"minimumFractionDigits":2},"short":{"maximumFractionDigits":0,"minimumFractionDigits":0}}}},
  vueI18nLoader: false,
  locales: [{"code":"fr-FR","iso":"fr-FR","file":"fr-FR.json"}],
  defaultLocale: "fr-FR",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/usr/src/bs-frontend/locales",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"fr-FR","iso":"fr-FR","file":"fr-FR.json"}],
  localeCodes: ["fr-FR"],
}

export const localeMessages = {
  'fr-FR.json': () => import('../../locales/fr-FR.json' /* webpackChunkName: "lang-fr-FR.json" */),
}
