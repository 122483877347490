import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_sentryserver_6ca8333a from 'nuxt_plugin_sentryserver_6ca8333a' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_63f274db from 'nuxt_plugin_sentryclient_63f274db' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_5a97f8f6 from 'nuxt_plugin_plugin_5a97f8f6' // Source: ./vuetify/plugin.js (mode: 'all')
import nuxt_plugin_plugin_73805331 from 'nuxt_plugin_plugin_73805331' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_gtm_ea844f96 from 'nuxt_plugin_gtm_ea844f96' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_passwordprotect_5bdc16c8 from 'nuxt_plugin_passwordprotect_5bdc16c8' // Source: ./password-protect.js (mode: 'all')
import nuxt_plugin_pluginutils_7e344a7c from 'nuxt_plugin_pluginutils_7e344a7c' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_784c28c7 from 'nuxt_plugin_pluginrouting_784c28c7' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_0ae7d008 from 'nuxt_plugin_pluginmain_0ae7d008' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_9da29172 from 'nuxt_plugin_cookieuniversalnuxt_9da29172' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_workbox_8250820a from 'nuxt_plugin_workbox_8250820a' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_3aa849e4 from 'nuxt_plugin_metaplugin_3aa849e4' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_3158fefc from 'nuxt_plugin_iconplugin_3158fefc' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_axios_33be359f from 'nuxt_plugin_axios_33be359f' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_apidefaults_7c10b30e from 'nuxt_plugin_apidefaults_7c10b30e' // Source: ../plugins/shopware-pwa/api-defaults.js (mode: 'all')
import nuxt_plugin_apiclient_1475fb60 from 'nuxt_plugin_apiclient_1475fb60' // Source: ../plugins/shopware-pwa/api-client.js (mode: 'all')
import nuxt_plugin_cookieconsentmanager_46cb9e10 from 'nuxt_plugin_cookieconsentmanager_46cb9e10' // Source: ../plugins/cookie-consent-manager.js (mode: 'client')
import nuxt_plugin_entitiesparsercsr_77514815 from 'nuxt_plugin_entitiesparsercsr_77514815' // Source: ../plugins/shopware-pwa/entities-parser/entities-parser.csr.js (mode: 'client')
import nuxt_plugin_convertWishlist_5211e855 from 'nuxt_plugin_convertWishlist_5211e855' // Source: ../plugins/convertWishlist.js (mode: 'client')
import nuxt_plugin_entitiesparserssr_dac25bf6 from 'nuxt_plugin_entitiesparserssr_dac25bf6' // Source: ../plugins/shopware-pwa/entities-parser/entities-parser.ssr.js (mode: 'server')
import nuxt_plugin_i18n_55a57d60 from 'nuxt_plugin_i18n_55a57d60' // Source: ../plugins/shopware-pwa/i18n.js (mode: 'all')
import nuxt_plugin_veevalidate_1a0c1998 from 'nuxt_plugin_veevalidate_1a0c1998' // Source: ../plugins/vee-validate.js (mode: 'all')
import nuxt_plugin_pricefilter_689e51b1 from 'nuxt_plugin_pricefilter_689e51b1' // Source: ../plugins/filters/price-filter.js (mode: 'all')
import nuxt_plugin_truncatefilter_77d17662 from 'nuxt_plugin_truncatefilter_77d17662' // Source: ../plugins/filters/truncate-filter.js (mode: 'all')
import nuxt_plugin_notlastelementfilter_75c3c82f from 'nuxt_plugin_notlastelementfilter_75c3c82f' // Source: ../plugins/filters/notlastelement-filter.js (mode: 'all')
import nuxt_plugin_ratingfilter_472d474e from 'nuxt_plugin_ratingfilter_472d474e' // Source: ../plugins/filters/rating-filter.js (mode: 'all')
import nuxt_plugin_notifications_15a88b85 from 'nuxt_plugin_notifications_15a88b85' // Source: ../plugins/notifications.js (mode: 'client')
import nuxt_plugin_passwordprotectipexclude_4a8219ee from 'nuxt_plugin_passwordprotectipexclude_4a8219ee' // Source: ../plugins/password-protect-ip-exclude.js (mode: 'all')
import nuxt_plugin_lazyhydrate_5fe36607 from 'nuxt_plugin_lazyhydrate_5fe36607' // Source: ../plugins/lazy-hydrate.js (mode: 'all')
import nuxt_plugin_globalcomponents_52ce1226 from 'nuxt_plugin_globalcomponents_52ce1226' // Source: ../plugins/global-components.js (mode: 'all')
import nuxt_plugin_trustedShops_3695ae62 from 'nuxt_plugin_trustedShops_3695ae62' // Source: ../plugins/tracking/trustedShops.js (mode: 'client')
import nuxt_plugin_googletagmanager_20ce44ef from 'nuxt_plugin_googletagmanager_20ce44ef' // Source: ../plugins/tracking/google-tag-manager.js (mode: 'client')
import nuxt_plugin_facebookpixelweb_3445668f from 'nuxt_plugin_facebookpixelweb_3445668f' // Source: ../plugins/tracking/facebook-pixel-web.js (mode: 'client')
import nuxt_plugin_bstracking_a8be52f4 from 'nuxt_plugin_bstracking_a8be52f4' // Source: ../plugins/tracking/bs-tracking.js (mode: 'client')
import nuxt_plugin_firebasemessaging_c35cfbd2 from 'nuxt_plugin_firebasemessaging_c35cfbd2' // Source: ../plugins/tracking/firebase-messaging.js (mode: 'client')
import nuxt_plugin_sentryclient_418f831a from 'nuxt_plugin_sentryclient_418f831a' // Source: ../plugins/sentry.client.js (mode: 'client')
import nuxt_plugin_meta_21d572fa from 'nuxt_plugin_meta_21d572fa' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"titleTemplate":"%s | Baby-Sweets.fr","title":"Baby-Sweets","meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""}],"link":[{"rel":"preconnect","href":"https:\u002F\u002Fapi.baby-sweets.de"},{"rel":"preconnect","href":"https:\u002F\u002Fimg.baby-sweets.de"},{"rel":"preconnect","href":"https:\u002F\u002Fbs-sentry.brl-server.de\u002F"}],"style":[],"script":[{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);}})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":""}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_6ca8333a === 'function') {
    await nuxt_plugin_sentryserver_6ca8333a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_63f274db === 'function') {
    await nuxt_plugin_sentryclient_63f274db(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_5a97f8f6 === 'function') {
    await nuxt_plugin_plugin_5a97f8f6(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_73805331 === 'function') {
    await nuxt_plugin_plugin_73805331(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_ea844f96 === 'function') {
    await nuxt_plugin_gtm_ea844f96(app.context, inject)
  }

  if (typeof nuxt_plugin_passwordprotect_5bdc16c8 === 'function') {
    await nuxt_plugin_passwordprotect_5bdc16c8(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_7e344a7c === 'function') {
    await nuxt_plugin_pluginutils_7e344a7c(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_784c28c7 === 'function') {
    await nuxt_plugin_pluginrouting_784c28c7(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_0ae7d008 === 'function') {
    await nuxt_plugin_pluginmain_0ae7d008(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_9da29172 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_9da29172(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_8250820a === 'function') {
    await nuxt_plugin_workbox_8250820a(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_3aa849e4 === 'function') {
    await nuxt_plugin_metaplugin_3aa849e4(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_3158fefc === 'function') {
    await nuxt_plugin_iconplugin_3158fefc(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_33be359f === 'function') {
    await nuxt_plugin_axios_33be359f(app.context, inject)
  }

  if (typeof nuxt_plugin_apidefaults_7c10b30e === 'function') {
    await nuxt_plugin_apidefaults_7c10b30e(app.context, inject)
  }

  if (typeof nuxt_plugin_apiclient_1475fb60 === 'function') {
    await nuxt_plugin_apiclient_1475fb60(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_cookieconsentmanager_46cb9e10 === 'function') {
    await nuxt_plugin_cookieconsentmanager_46cb9e10(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_entitiesparsercsr_77514815 === 'function') {
    await nuxt_plugin_entitiesparsercsr_77514815(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_convertWishlist_5211e855 === 'function') {
    await nuxt_plugin_convertWishlist_5211e855(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_entitiesparserssr_dac25bf6 === 'function') {
    await nuxt_plugin_entitiesparserssr_dac25bf6(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_55a57d60 === 'function') {
    await nuxt_plugin_i18n_55a57d60(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_1a0c1998 === 'function') {
    await nuxt_plugin_veevalidate_1a0c1998(app.context, inject)
  }

  if (typeof nuxt_plugin_pricefilter_689e51b1 === 'function') {
    await nuxt_plugin_pricefilter_689e51b1(app.context, inject)
  }

  if (typeof nuxt_plugin_truncatefilter_77d17662 === 'function') {
    await nuxt_plugin_truncatefilter_77d17662(app.context, inject)
  }

  if (typeof nuxt_plugin_notlastelementfilter_75c3c82f === 'function') {
    await nuxt_plugin_notlastelementfilter_75c3c82f(app.context, inject)
  }

  if (typeof nuxt_plugin_ratingfilter_472d474e === 'function') {
    await nuxt_plugin_ratingfilter_472d474e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_notifications_15a88b85 === 'function') {
    await nuxt_plugin_notifications_15a88b85(app.context, inject)
  }

  if (typeof nuxt_plugin_passwordprotectipexclude_4a8219ee === 'function') {
    await nuxt_plugin_passwordprotectipexclude_4a8219ee(app.context, inject)
  }

  if (typeof nuxt_plugin_lazyhydrate_5fe36607 === 'function') {
    await nuxt_plugin_lazyhydrate_5fe36607(app.context, inject)
  }

  if (typeof nuxt_plugin_globalcomponents_52ce1226 === 'function') {
    await nuxt_plugin_globalcomponents_52ce1226(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_trustedShops_3695ae62 === 'function') {
    await nuxt_plugin_trustedShops_3695ae62(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googletagmanager_20ce44ef === 'function') {
    await nuxt_plugin_googletagmanager_20ce44ef(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_facebookpixelweb_3445668f === 'function') {
    await nuxt_plugin_facebookpixelweb_3445668f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_bstracking_a8be52f4 === 'function') {
    await nuxt_plugin_bstracking_a8be52f4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_firebasemessaging_c35cfbd2 === 'function') {
    await nuxt_plugin_firebasemessaging_c35cfbd2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_418f831a === 'function') {
    await nuxt_plugin_sentryclient_418f831a(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_21d572fa === 'function') {
    await nuxt_plugin_meta_21d572fa(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
